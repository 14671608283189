@import "../../../shared";

.persons-new {
    &-split {
        display: grid;
        grid-template-columns: 1fr 1fr;

        &-left {
            padding: 5px;
            min-height: 100vh;
        }

        &-right {
            padding: 5px;
            max-height: 90vh;
            overflow: auto;
        }
    }
}