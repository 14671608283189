@import '../../shared';

.statistics {
    width: 80%;
    margin: 0 auto;
    &-charts {
        display: grid;
        grid-template-rows: 1fr 1fr;
        row-gap: 25px;
        padding: 5px;
        min-height: 800px;
        &-chart {
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 50px;
        }
    }

    &-toolbar {
        display: inline-flex;
        margin: 15px;
        &-filters{
            display: inline-flex;
            margin: 15px;

            &-date {
                &-from, &-to {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                }
            }

            &-columns {
                margin: 5px 0;
                border: $border-size solid $border-color;
                border-radius: 5px;
                overflow-y: scroll;
                min-width: 200px;
                max-height: 300px;

                &-column {
                    padding: 5px;
                    border-bottom: $border-size solid $border-color;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                }
            }
        }
    }
}