@import '../../../shared';

.vacancies-modal {
    
    top: 50%;
    transform: translateY(-50%);
    
    &-body {
        display: flex;
        flex-direction: column;
        height: 400px;

        &-vacancy-cards {
            overflow-y: scroll;
            flex-grow: 1
        }
    }

    &-footer {
        text-align: right;
    }
}

html[data-theme="dark"] {
    .vacancies-modal {
        .content, .header {
            color: $text-color-light;
            background-color: $background-color-dark;
            border-width: 1px;
            border-color: $background-color-light-dark;
            box-shadow: unset;
        }
        input {
            color: $text-color-light;
        }
    }
}
