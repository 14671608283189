@import '../../shared';

.votes-modal {
    &-list {
        &-description {
            display: inline-flex;
            align-items: center;
            &-icons {
                margin-right: .25rem;
                &-background {
                    margin-right: 0!important;
                }
            }
        }
    }
}

html[data-theme="dark"] {
    .votes-modal {
        .votes-modal-content,
         .votes-modal-header {
            color: $text-color-light;
            background-color: $background-color-dark;
            border-width: 1px;
            border-color: $background-color-light-dark;
            box-shadow: unset;
        }

    }
}


