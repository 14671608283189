@import '../../../../shared';

.relation {
    position: relative;
    min-width: 270px;
    padding: 0;
    color: rgba(112, 112, 112, .6);

    display: grid;
    grid-template-rows: 1fr 1fr;

    &-top {
        display: flex;
        flex-direction: row;
        column-gap: 10px;
        border-bottom: 1px solid #707070;
    }

    &-bottom {
        display: flex;
        flex-direction: row;
        column-gap: 10px;
    }

    &-delete-button {
        margin-left: auto;
    }

    &-stage {
        padding: 2px;
        &-name {
            border: 1px solid $border-color;
            border-radius: 5px;
            background-color: transparent;
            padding: 1px;
            color: #707070;
            cursor: pointer;

            transition-property: background-color border-radius;
            transition-duration: 150ms;

            &:hover {
                background-color: #d6d6d6;
                border-radius: 5px;
            }

            &:focus {
                background-color: #d4d4d4;
                border-radius: 5px;
                outline-color: gray;
            }
            
            &:active {
                background-color: #bdbdbd;
                border-radius: 5px;
            }
        }
        &-dropdown {
            max-height: 200px;
            overflow-y: scroll;
            &-item {
                border-top: 1px solid #707070;
                cursor: pointer;

                &-chosen {
                    font-weight: 700;
                }
                &-current {
                    opacity: .6;
                }
                &-first {
                    cursor: pointer;
                }
                
                &:hover {
                    background-color: #d6d6d6;
                }

            }
        }
        
        &-confirm-button {
            
            padding: 0;
            border: none;
            color: inherit;
            background-color: transparent;
            border-bottom: 1px solid #707070;
            padding: 5px;
            cursor: pointer;

            transition-property: background-color border-radius;
            transition-duration: 150ms;

            &:hover {
                background-color: #d6d6d6;
                border-radius: 5px;
            }

            &:focus {
                background-color: #d4d4d4;
                border-radius: 5px;
                outline-color: gray;
            }
            
            &:active {
                background-color: #bdbdbd;
                border-radius: 5px;
            }
        }
    }
}

.processing-cancel-reason-form {
    padding: 10px;
    border-radius: 5px;
    background-color: white;
    border: 1px solid #707070;
}