@import "../../../shared";

.candidates-container {
    padding: 5px;
}

.candidates {
    border-color: $border-color;

    &-toolbar {
        position: sticky;
        top: 0;
    }

    &-person {
        display: grid;
        grid-template-columns: 2fr 3fr;
        column-gap: 5px;

        margin: 5px;

        &-info {
            display: grid;
            grid-template-rows: 1fr 1fr 1fr;

            height: max-content;

            border: $border-size solid $border-color;
            border-radius: 5px;

            padding: 5px;

            &-contacts {
                display: grid;
                grid-template-columns: 1fr 1fr;
            }
        }

        &-relations {
            display: flex;
            flex-direction: column;
            row-gap: 5px;

            height: max-content;

            &-relation {
                display: grid;
                grid-template-columns: 1fr 2fr;
                
                padding: 5px;

                
                border: $border-size solid $border-color;
                border-radius: 5px;

                &-chain {
                    display: flex;
                    flex-wrap: wrap;

                    height: max-content;

                    &-link {
                        padding: 1px 5px;
                        border: $border-size solid $border-color;
                        border-radius: 5px;
                        cursor: pointer;

                        &-pop-up {
                            position: absolute;
                            padding: 1px 5px;
                            border: $border-size solid $border-color;
                            border-radius: 5px;
                            max-width: 500px;
                            background-color: $background-color-light;
                        }
                    }
                }
            }
        }
    }
}
.dark {
    .candidates {
        color: $text-color-light;
        &-person-relations-relation-chain-link-pop-up {
            background-color: $background-color-dark;
        }
    }
}
.load-button {
    text-align: center;
}

.delete-modal{
    width: 400px!important;
    &-confirm-button {
        background-color: #B03060!important;
        &:hover {
            background-color: $background-color-dark-hover!important;
        }
    }
}

.context-menu {
    background-color: $background-color-light;
    color: $text-color-dark;
    border: $border-size solid $border-color;
    border-radius: 5px;
    padding: 5px;
    width: 150px;
    position: absolute;

    &-option {
        cursor: pointer;
    }
  }