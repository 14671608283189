@import '../../../shared';

.persons-duplicate-modal {
    width: 80%!important;
    
    &-content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 15px;
    }
}


html[data-theme="dark"] {
    .persons-duplicate-modal {
        .persons-duplicate-modal-content-container,
        .persons-duplicate-modal-header {
            color: $text-color-light;
            background-color: $background-color-dark;
            border-width: 1px;
            border-color: $background-color-light-dark;
            box-shadow: unset;
        }

    }
}
