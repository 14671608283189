@import '../../../../shared';

.vacancy-creation-card {
    display: flex;
    flex-direction: row;

    width: 100%;

    margin: 5px 0;
    padding: 5px;

    border: 1px solid #707070;
    border-radius: 5px;

    &-content {
        display: grid;
        grid-template-columns: 2fr 3fr;
        flex-grow: 1;
    }

    &-description {
        max-height: 150px;
        overflow-y: scroll;

        &-textarea {
            width: 100%;
            height: 100%;
        }
    }

    &-info {
        display: flex;
        flex-direction: column;
        &-input { 
            padding: 0;
            border: none;
            font: inherit;
            color: inherit;
            background-color: transparent;
            cursor: text;
            min-width: 200px;
        
            border-bottom: 1px solid #707070;
            padding: 5px;
        
            transition-property: all;
            transition-duration: 150ms;

            outline: none;
        
            &.selected {
                background-color: rgb(200, 200, 200);
                border-radius: 5px;
            }

            &:hover {
                background-color: #f1f1f1;
                border-radius: 5px;
            }
        
            &:focus {
                border-radius: 5px;
                outline-color: gray;
            }
        
            &:active {
                background-color: #f1f1f1;
                border-radius: 5px;
            }

        }
    }
    
    &-toolbar {
        display: flex;
        flex-direction: column;
    }
}

html[data-theme="dark"] {
    .vacancy-creation-card {
        &-info {
            &-input { 
                &:hover {
                    color: $text-color-dark
                }

            }
        }
    }
}