@import '../../../shared';

.legend {
    text-align: center;
    padding-left: 40px;
    
    &-item {
        display: inline-block;
        margin: 0 5px;
        
        &-hide {
            opacity: 0.6;
        }

        &-color-box {
            display: inline-block;
            width: 10px;
            height: 10px;
            margin-right: 2px;
        }   
    }
}

.recharts-tooltip-label {
    color: $text-color-dark;
}