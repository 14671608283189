@import "../../shared";

.persons-card-chosen {
    background: $background-color-secondary-light!important;
    z-index: 3;
}

.dark .persons-card-chosen {
    background: $background-color-secondary-dark!important;
}

.search-form-button-icon-corner {
    text-shadow: none!important;
}