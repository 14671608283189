@import '../../shared';

.ui-table {
    table-layout: fixed;
    min-width: 100%;

    border: $border-size solid $border-color;
    border-radius: 5px;
    border-spacing: 0;

    &-container {
        overflow: hidden;
    }
    &-download-icon-container {
        text-align: right;
        i {
            cursor: pointer;
        }
    }

    &-content {
        overflow: scroll;
        height: fit-content;
        max-height: 500px;
    }

    &-cross-cell {
        position: sticky;
        top: 0;
        left: 0;
        z-index: 3;
        background-color: $background-color-light;
        border: $border-size solid $border-color;
        padding: 5px;
        box-shadow: 1px 1px 2px #000;
        background-image: linear-gradient(to bottom left,  transparent calc(50% - 1px), $border-color, transparent calc(50% + 1px));
        font-size: 12px;
        &-label-x {
            position: absolute;
            top: 0;
            right: 0;
        }
        
        &-label-y {
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }

    &-header-x-cell {
        background-color: $background-color-light;
        position: sticky;
        top: 0;
        z-index: 2;
        border: $border-size solid $border-color;
        padding: 5px;
        box-shadow: 0 1px 2px #000;
    }

    &-body {
        &-header-y-cell {
            position: sticky;
            left: 0;
            z-index: 2;
            background-color: $background-color-light;
            border: $border-size solid $border-color;
            padding: 5px;
            box-shadow: 1px 0 2px #000;
        }

        &-cell {
            border: $border-size solid $border-color;
            padding: 5px;
        }
    }
    
    .cell-chosen {
        background-color: $background-color-dark-light;
    }
}

.dark {
    .ui-table {
        &-header-x-cell, &-body-header-y-cell, &-cross-cell {
            background-color: $background-color-dark;
        }

        .cell-chosen {
            background-color: $background-color-light-dark;
        }
    }
}