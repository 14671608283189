@import "../../shared";

.stages {
    &-split {
        display: grid;
        grid-template-columns: 1fr 1fr;

        &-left {
            padding: 5px;
            min-height: 100vh;

            &-selected {
                background: rgba(0,0,0,.1)!important;
            }
        }

        &-right {
            padding: 5px;
            max-height: 90vh;
        }
    }
}

.dark {
    .stages{
        &-split-left {
            &-selected {
                background-color: $background-color-light-dark!important;
                color: $text-color-light!important;
            }
            .item .header {
                color: $text-color-light!important;
            }
        }
    }
}

.sticky-ui {
    top: 5px;
    position: sticky;
    z-index: 10;
}

#stages-color-input-label {
    padding: 0px !important
}

.stages-color-input input[type="color"] {
    height: 38px;
    padding: initial !important;
    opacity: 0;
  }
