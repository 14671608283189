@import "../../shared";

.navigator {
    position: fixed;
    width: 150px;
    left: -135px;
    bottom: 10px;
    transition: transform .5s ease-out;
    z-index: 11;
    
    &:hover {
        transform: translateX(135px);
    }

    &-menu {
        border-right: .5vw #a0a0a0 solid!important;
    }
}

.dark .navigator {
    .navigator-menu {
        background-color: $background-color-dark;
        .item {
            color: $text-color-light;
        }
        .item:hover {
            background-color: $background-color-dark-hover;
        }
    }
}
