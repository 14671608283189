@import '../../shared';

.confirm-modal {
    width: auto!important;

    top: 50%;
    transform: translateY(-50%);

    .confirm-modal-content {
        padding: 10px;
        text-align: center;

        &-button {
            margin: 0 10px;
        }
    }
}

html[data-theme="dark"] {
    .confirm-modal {
        border: $border-size solid #707070;
        border-radius: 5px;
        background: none;

        .confirm-modal-content, .confirm-modal-header {
            color: $text-color-light;
            background-color: $background-color-dark;
        }

        &-content-button:hover {
            color: $text-color-dark;
        }
    }
}
