@import "./shared";

#root {
    height: 100%;
}

.page {
    display: block;
    margin: auto;
    height: 100%;
    // padding: 0 25px;
    
    &-header {
        padding: $default-padding;
        display: block;
        margin: auto;
        padding: 5px 0;
        border-bottom: 2px solid #EEEEEE;
        text-align: center;

        &-title {
            font-family: 'Roboto Slab', Georgia, Times, serif;
            font-style: normal;
            line-height: 31px;
            
            font-size: 32px;
            line-height: 39px;
            font-weight: 400;
            color: #666666;
        }
    }

    &-body {
        min-height: 100%;
        // padding: 0;
    }

    &-footer {
        display: block;
        margin: auto;
        padding: 5px 0;
        border-top: 2px solid #EEEEEE;
    }
}

html[data-theme="dark"] {
    .page{
        &-header {
            color: $text-color-light;
            background-color: $background-color-dark;
            border-bottom-color: $background-color-light-dark;
        }
  
        &-body {
            background-color: $background-color-dark;
            color: $text-color-light;
        }

        &-footer {
            border-top-color: $background-color-light-dark;
            color: $text-color-light;
        }
    }
  
    .ui {
        &.card, 
        &.accordion,
        &.segment, 
        &.dropdown, 
        &.dropdown .menu .item,
        &.menu .item,
        &.table,
        &.table th,
        textarea {
            color: $text-color-light;
            background-color: $background-color-dark;
            border-width: 1px;
            border-color: $background-color-light-dark;
            box-shadow: unset;

            .header {
                color: $text-color-light;
            }
        }

        &.menu .item:hover{
            color: $text-color-light;
        }

        &.menu .item.active{
            color: $text-color-dark;
        }

        &.selectable.table tr:hover {
            color: $text-color-light!important;
            background: $background-color-dark-hover!important;
        }

        &.dropdown.active.selection .text {
            color: $text-color-light;
        }

        &.card {
            border-style: solid;
        }

        &.accordion {
            border-style: solid;
            .title, 
            .title:hover, 
            .title.active {
                color: $text-color-light;
            }
        }

        &.dropdown .menu .item:hover {
            background: $background-color-dark-hover;
            color: $text-color-dark
        }

        &.button {
            color: $text-color-light;
            background-color: $background-color-light-dark;
        }
        &.button:hover {
            color: $text-color-dark;
            background-color: $background-color-dark-hover;
        }

        
        &.input {
            input {
                color: $text-color-light;
                background-color: $background-color-dark;
                border-color: $background-color-light-dark;
            }      
            input:focus {
                background-color: $background-color-dark;
                color: $text-color-light;
            }
        }

        textarea:focus {
            color: $text-color-light;
            background-color: $background-color-dark;
        }
    }

    select {
        color: $text-color-light;
        background-color: $background-color-dark;
        border-radius: inherit;
    }
  
    .comment .content {
        .metadata, .actions a, .text, .author{
            color: $text-color-light
        }
        .actions a:hover {
            color: $background-color-dark-hover
        }
    }
}

html[data-theme="dark"] {
    background-color: $background-color-dark;
}
