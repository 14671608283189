@import "../../shared";

.recruiter {
    &-split {
        display: grid;
        grid-template-columns: 1fr 1fr;

        &-left {
            padding: 5px;
            min-height: 100vh;
        }

        &-right {
            padding: 5px;
            display: flex;
            flex-direction: column;
            word-break: break-all;
        }
    }

    &-sticky {
        top: 0;
        position: sticky;
    }

    &-top {
        overflow: auto;
        flex-grow: 1;
        margin-bottom: 0!important;
        height: 70%;
    }

    &-bottom {
        overflow: auto;
        height: 30%;
        margin: 1em 0 0 0;
    }
}

.sticky-ui {
    top: 5px;
    position: sticky;
    z-index: 10;
}