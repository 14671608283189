@import '../../shared';

.styled-button-ui {
    padding: 0;
    border: none;
    color: inherit;
    background-color: transparent;
    border-bottom: 1px solid #707070;
    padding: 5px;
    cursor: pointer;

    transition-property: background-color, border-radius;
    transition-duration: 150ms;

    &:hover {
        background-color: #d6d6d6;
        border-radius: 5px;
    }

    &:focus {
        background-color: #d4d4d4;
        border-radius: 5px;
        outline-color: gray;
    }
    
    &:active {
        background-color: #bdbdbd;
        border-radius: 5px;
    }

    i {
        margin: 0;
    }
}