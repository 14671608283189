@import '../../shared';

.input-select-multiple {

    display: inline-block;
    position: relative;
    width: fit-content;

    &-chosen-options {
        display: flex;
        flex-wrap: wrap;

        &-option {
            border-bottom: 1px solid #707070;
            &:hover {
                border-radius: 5px;

            }
        }
    }
    &-input {
        border: none;
        background-color: transparent;
        width: 100%;
    }

    &-options {
        position: absolute;
        
        max-height: 300px;
        width: 100%;
        overflow-y: scroll;
        
        background-color: white;
        padding: 10px;
        border: 1px solid #707070;
        border-radius: 5px;

        z-index: 2;

        &-option-description {
            font-size: .8em;
            color: #707070;
        }

        &-empty {
            color: #A9A9A9;
        }
        
        &-divider {
            width: 100%;
            margin-top: 5px;
            margin-bottom: 5px;
            border-bottom: 1px solid #707070;
        }

        &-option { 
            display: inline-flex;
            justify-content: space-between;
            width: 100%;

            &-button {
                width: fit-content!important;
                height: fit-content;
            }
        }
    }

    &-button {
        padding: 0;
        border: none;
        color: inherit;
        background-color: transparent;
        padding: 5px;
        cursor: pointer;

        transition-property: background-color border-radius;
        transition-duration: 150ms;

        &:hover {
            background-color: #d6d6d6;
            border-radius: 5px;
        }

        &:focus {
            background-color: #d4d4d4;
            border-radius: 5px;
            outline-color: gray;
        }
        
        &:active {
            background-color: #bdbdbd;
            border-radius: 5px;
        }
    }

    & > span.icon {
        position: absolute;
        padding-top: 6px;
        padding-left: 3px;
        margin: 0 !important;
        opacity: 0.5;
    }

    padding: 0;
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
    cursor: text;
    min-width: 200px;

    border-bottom: 1px solid #707070;
    padding: 5px;

    transition-property: all;
    transition-duration: 150ms;

    &.selected {
        background-color: rgb(200, 200, 200);
        border-radius: 5px;
    }

    &:hover {
        background-color: #f1f1f1;
        border-radius: 5px;
    }

    &:focus {
        border-radius: 5px;
        outline-color: gray;
    }

    &:active {
        background-color: #f1f1f1;
        border-radius: 5px;
    }

    &::placeholder {
        color: #cccccc;
    }

    &.with-icon {
        padding-left: 20px;
    }
}

html[data-theme="dark"] {
    .input-select-multiple {
        &-options {
            .select-option {
                color: $text-color-dark
            }
        }

        &.selected {
            background-color: rgb(46, 45, 45);
            border-radius: 5px;
        }

        &:hover {
            background-color: #d6d6d6;
            border-radius: 5px;
        }
    
        &:focus {
            border-radius: 5px;
            outline-color: gray;
        }
    
        &:active {
            background-color: #f1f1f1;
            border-radius: 5px;
        }

        &::placeholder {
            color: #cccccc;
        }
    }
    
}