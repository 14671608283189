@import "../../shared";

.vacancies {
    &-split {
        display: grid;
        grid-template-columns: 1fr 1fr;
        &-left {
            padding: 5px;
            min-height: 100vh;
            max-width: 50vw;

            .ribbon.label {
                z-index: 3;
            }
            &-card {
                &-chosen {
                    background: $background-color-secondary-light!important;
                }

                &-status {
                    float: right;
                }
            }
        }

        &-right {
            padding: 5px;
            max-height: 90vh;
            max-width: 50vw;
        }
    }

    &-sticky {
        top: 0;
        position: sticky;
    }

    &-top {
        overflow: auto;
        height: 70%;
    }

    &-bottom {
        overflow: auto;
        height: 30%;
    }
}

.sticky-ui {
    top: 5px;
    position: sticky;
    z-index: 10;
}

.error-label {
    margin-top: 1em!important ;
}

.dark {
    .ui {
        &.ribbon.label {
            border-top-color: $background-color-light-dark;
        }
    }

    .vacancies {
        &-split {
            &-left {
                &-card {
                    &-chosen {
                        background: $background-color-secondary-dark!important;
                    }
                }
            }
        }
    }
}