@import '../../shared';


.tags-list {
    &-container {
        text-align: right;
    }

    &-popup {
        max-height: 350px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        &-input {
            width: 100%;
            padding: 0;
            border: none;
            color: inherit;
            background-color: transparent;
            border-bottom: 1px solid #707070;
            padding: 5px;
            cursor: pointer;

            transition-property: background-color border-radius;
            transition-duration: 150ms;

            &:hover {
                background-color: #d6d6d6;
                border-radius: 5px;
            }

            &:focus {
                background-color: #d4d4d4;
                border-radius: 5px;
                outline-color: gray;
            }
            
            &:active {
                background-color: #bdbdbd;
                border-radius: 5px;
            }
        }

        &-options {
            overflow-y: scroll;
            max-height: 200px;

            &-option {
                cursor: pointer;
                border-bottom: 1px solid #707070;
                padding-left: 5px;
    
                transition-property: background-color border-radius;
                transition-duration: 150ms;
    
                &:hover {
                    background-color: #d6d6d6;
                    border-radius: 5px;
                }
            }
        }
    }

    i {
        cursor: pointer;
    }

    &-excess-tag-icon {
        border: 1px solid $border-color;
        border-radius: 5px;
        padding: 1px;
        cursor: pointer;
    }
}

.dark {
    .tags-list {
        &-excess-tag-icon {
            border-color: #707070;
        }
    }
}
