@import '../../shared';

.tag {
    display: inline-block;
    position: relative;
    border: $border-size solid $border-color;
    border-radius: 5px; 
    white-space: nowrap;
    min-width: 50px;
    max-width: 100px;
    text-align: center;
    overflow: hidden;
    vertical-align: bottom;

    &-label {
        position: absolute;
        left: 0;
        right: 0;
        display: inline-block;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 100%;
        vertical-align: middle;

        &-shadow {
            opacity: 0;
        }
    }

    &-input {
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        margin: 0;
        border: none;
        border-radius: 5px;
        z-index: 3;
        font-size: 12px;
    }

    &-icon {
        cursor: pointer;
    }

    &-menu {
        display: none;

        &-popup {
            &-option {
                cursor: pointer;
                border-bottom: 1px solid #707070;
                padding-left: 5px;
    
                transition-property: background-color border-radius;
                transition-duration: 150ms;
    
                &:hover {
                    background-color: #d6d6d6;
                    border-radius: 5px;
                }
            }
            &-color-input-container {
                display: inline-flex;
                height: 1rem;
                width: 1rem;
                vertical-align: middle;
                border: 1px solid;
                border-radius: 50%;
                margin-left: 5px;
                input[type=color] {
                    height: 100%;
                    width: 100%;
                    opacity: 0;
                }
            }

            &-save-button {
                padding: 0;
                border: none;
                color: inherit;
                background-color: transparent;
                border-bottom: 1px solid #707070;
                padding: 5px;
                cursor: pointer;

                width: 100%;

                transition-property: background-color border-radius;
                transition-duration: 150ms;

                &:hover {
                    background-color: #d6d6d6;
                    border-radius: 5px;
                }

                &:focus {
                    background-color: #d4d4d4;
                    border-radius: 5px;
                    outline-color: gray;
                }
                
                &:active {
                    background-color: #bdbdbd;
                    border-radius: 5px;
                }
            }
        }
    }

    &:hover {
        .tag-menu {
            display: inline;
            position: absolute;
            right: 0;
        }
        .tag-label {
            max-width: calc(100% - 50px);
        }
    }
}