@import "../../../shared";

.persons-list-card-chosen {
    background: $background-color-secondary-light!important;
}

.dark .persons-list-card-chosen {
    background: $background-color-secondary-dark!important;
}

.search-form-button-icon-corner {
    text-shadow: none!important;
}