@import "../../../shared";

.person-card {
    &-relations {
        display: flex;
        flex-direction: column;
        width: fit-content;
        margin: 10px 14px
    }
    &-meta {
        font-size: 1em;
        color: rgba(0, 0, 0, .4);
    }
    &-grid {
        padding-top: 15px!important;
        padding-bottom: 15px!important;
        &-row {
            padding-top: 0!important;
            padding-bottom: 0!important;
            align-items: center!important;
            &-header {
                font-weight: 700;
                font-size: 18px;
                padding: 0 12px!important;

                &-edited {
                    padding: 0!important;
                }

                &-column {
                    width: auto!important;
                    padding-right: 2px!important;
                    padding-left: 2px!important;
                }
            }

            &-tags {
                padding-bottom: 0!important
            }
        }
    }
    
    &-vacancies-modal-trigger-button {
        padding: 0;
        border: none;
        color: inherit;
        background-color: transparent;
        border-bottom: 1px solid #707070;
        padding: 5px;
        cursor: pointer;

        transition-property: background-color border-radius;
        transition-duration: 150ms;

        &:hover {
            background-color: #d6d6d6;
            border-radius: 5px;
        }

        &:focus {
            background-color: #d4d4d4;
            border-radius: 5px;
            outline-color: gray;
        }
        
        &:active {
            background-color: #bdbdbd;
            border-radius: 5px;
        }
    }
    
    &-copy-icon, &-edit-icon {
        cursor: pointer;
    }

    &-booking-button, &-edit-icon {
        float: right;
    }
}

.dark {
    .person-card-meta {
        color: rgba(255,255,255,.4)
    }
    .ui {
        &.selectable.table tr:hover .dropdown  {
            color: $text-color-light!important;
            background: $background-color-dark-hover!important;
        }
    }
}