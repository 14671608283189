@import '../../../../shared';

.vacancy-card {
    display: flex;
    flex-direction: row;

    width: 100%;

    margin: 5px 0;
    padding: 5px;

    border: 1px solid #707070;
    border-radius: 5px;

    &-content {
        display: grid;
        grid-template-columns: 2fr 3fr;
        flex-grow: 1;
    }

    &-description {
        max-height: 150px;
        overflow-y: scroll;
    }

    &-toolbar {
        display: flex;
        flex-direction: column;
    }
}