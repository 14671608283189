@import '../../shared';

.ui-search {
    display: inline-flex;

    &-container {
        display: inline-flex;
        flex-direction: column;
        position: relative;
    }

    &-chosen-value-label {
        max-width: 80%;

        position: absolute;
        top: 50%;
        left: 5.5px;
        transform: translateY(-50%);

        display: flex;
        align-items: center;

        padding: 3px 5.5px;

        border-radius: 5px;
        background-color: #e2e2e2;

        &-text {
            font-size: 15px;

            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        &-icon {
            display: flex;
            align-items: center;

            margin-right: 0;
            i::before {
                position: relative;
                top: -3px;
            }
        }
    }

    &-dropdown {
        position: relative;
        top: 14px;

        &-list {
            position: absolute;
            width: 100%;
            max-height: 400px;
            overflow-y: scroll;
            z-index: 12;
            box-shadow: 0 3px 6px #2b2b2b5e;

            background-color: $background-color-light;

            border: $border-size solid $border-color;
            border-radius: 5px;
            
            &-item {
                padding: 5px;
                cursor: pointer;

                &:hover {
                    background-color: $background-color-dark-hover;
                }
            }

            &-load-button {
                border: none;
                opacity: .6;

                &-container {
                    padding: 5px;
                    text-align: center;
                }
            }
        }
    }
}
.dark {
    .ui-search{
        
        &-chosen-value-label {
            color: $text-color-light;
            background-color: $background-color-light-dark;
        }
        
        &-icon {
            color: $text-color-light;
        }
        &-dropdown-list {
            background-color: $background-color-dark;
        }
    }
}