// variables here
$default-padding: 25px;
$default-margin: 10px;
$default-border-radius: 10px;
$border-color: #e6e6e7;
$border-size: 1px;
$hover-el-border-color: #aaaaaa;
$active-el-border-color: #888888;
$background-color-light: #ffffff;
$background-color-secondary-light: #eeeeee;
$background-color-dark-light: #e0e1e2;
$background-color-dark: #121212;
$background-color-secondary-dark: #222222;
$background-color-light-dark: #545455;
$background-color-dark-hover: #cacbcd;
$background-color-light-hover: rgba(0,0,0,.05);
$text-color-light: #ffffff;
$text-color-dark: #000000;
$text-color-service: rgba(255, 255, 255, 0.4);