@import '../../shared';

.styled-input-ui {
    padding: 0;
    border: none;
    outline: none;
    color: inherit;
    background-color: $background-color-light;
    border-left: $border-size solid #70707099;
    color: #70707099;
    line-height: 14px;
    padding: 5px;

    transition-property: background-color, line-height;
    transition-duration: 150ms;

    &:focus {
        line-height: 24px;
        border-color: #707070;
        color: #707070;
    }

    &::placeholder {
        color: #70707066;
    }
}


.dark {      
    .styled-input-ui {
        border-color:#54545499;
        color: #ffffff99;
        background-color: $background-color-dark;

        &:focus {
            line-height: 24px;
            border-color: $background-color-light-dark;
            color: $text-color-light;
        }
    }
}