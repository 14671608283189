@import '../../shared';

.profiles {
    &-split {
        display: grid;
        grid-template-columns: 1fr 2fr;

        &-left {
            padding: 5px;
            min-height: 100vh;
        }

        &-right {
            padding: 5px;
            max-height: 90vh;
        }
    }
}

.dark {
    .profiles { 
        text {
            fill: $text-color-light;
        }
        .c3-axis-y text {
            fill: $text-color-light;
        }
        .c3-axis-x text {
            fill: $text-color-light;
        }
        .c3 path, .c3 line {
            stroke: $background-color-light-dark;
        }
        .c3-tooltip {
            color: $text-color-dark;
        }
    }
}
