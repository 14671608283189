.select-option button {
    outline-color: gray;

    width: 100%;

    padding: 0;
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
    cursor: pointer;

    text-align: start;

    border-radius: 5px;

    padding: 5px;

    transition-property: background-color border-radius;
    transition-duration: 150ms;

    &:hover {
        background-color: #d6d6d6;
    }

    &:focus {
        background-color: #d4d4d4;
    }

    &:active {
        background-color: #bdbdbd;
    }

    &:hover:disabled,
    &:focus:disabled,
    &:active:disabled {
        background-color: #d6d6d6;
    }
    
    &:disabled {
        opacity: 0.5;
    }
}