.report-vacancy-ui {
    padding: 10px;
    border: 1px solid #dfdfdf;

    margin-top: 5px;
    max-width: 600px;
    cursor: pointer;
}

.divider-ui {
    border-top: 1px solid #bbb;
}